"use client";

import type { PropsWithChildren } from "react";
import { useSnackbarProvider } from "./snackbar_provider";
import SnackbarProvider from "./snackbar_context";

export const SnackbarProviderComponent = ({ children }: PropsWithChildren) => {
  return (
    <useSnackbarProvider.State>
      <SnackbarProvider>{children}</SnackbarProvider>
    </useSnackbarProvider.State>
  );
};
