"use client";

import type { PropsWithChildren } from "react";
import { useAppInitiatedEvent } from "@/src/ui/hooks/useAppInitiatedEvent";

const App = (props: PropsWithChildren) => {
  useAppInitiatedEvent();

  return <>{props.children}</>;
};

export default App;
