"use client";
import useEffectStrictMode from "@/src/ui/hooks/useEffectStrictMode";
import { userProviderStore } from "@/src/ui/providers/user.provider";

export const useAppInitiatedEvent = () => {
  return useEffectStrictMode(() => {
    const unsubscribeFn = userProviderStore.subscribe((state) => {
      if (state.isInitiated) {
        const event = new CustomEvent("app-initiated", { detail: {} });
        window.dispatchEvent(event);
        unsubscribeFn();
      }
    });
    userProviderStore.getState().init();
  });
};
