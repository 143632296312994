"use client";

import type { PropsWithChildren } from "react";
import React from "react";
import css from "./header_layout.css";
import { Link } from "@/src/ui/i18n";
import Image from "next/image";
import classNames from "classnames";
import { useUserProvider } from "@/src/ui/providers/user.provider";
import { locator } from "@/src/core/app/ioc/__generated__";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/__generated__/types";
import type { LogoutUseCase } from "@/src/core/users/domain/use_cases/logout_use_case";
import { useUiProvider } from "../../providers/ui.provider";
import { MOBILE_WIDTH } from "../../utils/mobile";

function NavLink(props: { href: string; label: string }) {
  return (
    <li className={css.li}>
      <Link className={classNames(css.link)} href={props.href}>
        {props.label}
      </Link>
    </li>
  );
}

export const HeaderLayout = ({ children }: PropsWithChildren) => {
  const userIsLogged = useUserProvider((state) => state.isLogged);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const refMenu = React.useRef<HTMLDivElement>(null);
  const { isMobile, setIsMobile } = useUiProvider((state) => ({ isMobile: state.isMobile, setIsMobile: state.setIsMobile }));

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };

    window.addEventListener("resize", handleResize);
    setIsMobile(window.innerWidth < MOBILE_WIDTH);

    const onClick = (event: MouseEvent) => {
      const target = event.target as Node;
      if (refMenu.current && (!refMenu.current.contains(target) || target.nodeName === "A")) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", onClick);
    };
  }, [setIsMobile]);

  const userLogout = async () => {
    try {
      const useCase = await locator.get<IocProvider<LogoutUseCase>>(TYPES.LogoutUseCase)();
      await useCase.execute();
      window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={css.wrapper}>
      <header className={css.nav}>
        <Link href={"/"}>
          <Image src="/assets/images/header_logo.svg" className={css.logo} width={0} height={0} alt="KFC x Forza logo" />
        </Link>

        {isMobile ? (
          <div ref={refMenu} className={css.mobile}>
            <button className={css.mobileButton} onClick={() => setIsMenuOpen((prevState) => !prevState)}>
              <span className={css.mobileSpan}></span>
            </button>
            {isMenuOpen && (
              <div className={css.mobileMenu}>
                <div className={css.mobileAuthLinks}>
                  <Link className={css.mobileLink} href="/leaderboard">
                    Leaderboard
                  </Link>
                  <Link className={css.mobileLink} href="/circuito">
                    Circuito
                  </Link>
                  <Link className={css.mobileLink} href="/reglas">
                    Reglas
                  </Link>
                  {userIsLogged && (
                    <Link className={css.mobileLink} href="/enviar-tiempo">
                      Enviar tiempo
                    </Link>
                  )}
                  {userIsLogged && (
                    <Link className={css.mobileLink} href="/mis-tiempos">
                      Mis tiempos
                    </Link>
                  )}
                </div>
                {userIsLogged ? (
                  <div className={css.loginMobileLink}>
                    <span className={css.mobileLink} onClick={userLogout}>
                      Cerrar sesión
                    </span>
                  </div>
                ) : (
                  <div className={css.loginMobileLink}>
                    <Link className={css.mobileLink} href="/login">
                      Login
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <ul className={css.ul}>
            <NavLink href="/leaderboard" label="Leaderboard" />
            <NavLink href="/circuito" label="Circuito" />
            <NavLink href="/reglas" label="Reglas" />
            {userIsLogged && <NavLink href="/enviar-tiempo" label="Enviar tiempo" />}
            {userIsLogged && <NavLink href="/mis-tiempos" label="Mis tiempos"></NavLink>}
            <li className={css.li}>
              {userIsLogged ? (
                <span className={classNames(css.logoutLink)} onClick={userLogout}>
                  <span className={css.registerLinkLabel}>{"Cerrar sesión"}</span>
                </span>
              ) : (
                <Link className={classNames(css.registerLink)} href="/login">
                  <span className={css.registerLinkLabel}>{"Login"}</span>
                </Link>
              )}
            </li>
          </ul>
        )}
      </header>
      <main className={css.main}>{children}</main>
    </div>
  );
};
