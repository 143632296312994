import { toJson } from "@/src/common/utils/class_transformer";
import { DrupalDataModel } from "../models/drupal_data_model";
import CookieUtils from "@front_web_mrmilu/utils/src/cookie";
import type { DrupalFrontCookieData } from "@/src/core/app/data/models/drupal_front_cookie_data";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toDrupalJson = (data: any) => {
  if (!data) return "";
  const dataAsJson = toJson(data);
  const objectKeys = Object.keys(dataAsJson);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataAccordingToDrupalSchema = objectKeys.reduce((newObject: any, key) => {
    if (dataAsJson[key] === null || dataAsJson[key] === undefined) {
      return newObject;
    }

    newObject[key] = new DrupalDataModel(dataAsJson[key]);

    return newObject;
  }, {});

  return dataAccordingToDrupalSchema;
};

export const isUserLoggedIn = (): boolean => {
  const value = CookieUtils.getCookie("kfc_session_front");
  return !!value;
};

export const getUserFrontCookieData = (): DrupalFrontCookieData | null => {
  const value = CookieUtils.getCookie("kfc_session_front");
  if (!value) {
    return null;
  }

  const json = JSON.parse(value);
  return json as DrupalFrontCookieData;
};

export const deleteDrupalFrontCookie = (): void => {
  CookieUtils.eraseCookie("kfc_session_front");
};

export const getDrupalCsrfTokenFromCookie = (): string | null => {
  const cookieData = getUserFrontCookieData();
  if (!cookieData) {
    return null;
  }

  return cookieData.csrf_token;
};

export const getDrupalLogoutfTokenFromCookie = (): string | null => {
  const cookieData = getUserFrontCookieData();
  if (!cookieData) {
    return null;
  }

  return cookieData.logout_token;
};

export const getDrupalUserTagFromCookie = (): string | null => {
  const cookieData = getUserFrontCookieData();
  if (!cookieData) {
    return null;
  }

  return cookieData.username;
};
