import type { ConstructorType } from "@/src/common/interfaces/constructor_type";

export class Page<T> {
  items: Array<T> = [];
  page?: number;
  next?: number;

  constructor(params: ConstructorType<Page<T>>) {
    this.items = params.items;
    this.page = params.page;
    this.next = params.next;
  }
}
