import { createContext } from "react";
import type { SnackbarType } from "../view_models/snackbar";
import { Snackbar } from "../snackbar";
import { useSnackbarProvider } from "./snackbar_provider";

const SnackbarContext = createContext<{
  snackbars: Array<SnackbarType>;
}>({
  snackbars: []
});

export default function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const { snackbars, removeSnackbar } = useSnackbarProvider((state) => state);

  return (
    <SnackbarContext.Provider value={{ snackbars }}>
      {snackbars.map((snack, index) => (
        <Snackbar key={snack.key} style={{ top: 150 + index * 70 }} text={snack.text} handleClose={() => removeSnackbar(snack.key)} />
      ))}
      {children}
    </SnackbarContext.Provider>
  );
}
