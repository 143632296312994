import React from "react";
import css from "./snackbar.css";

interface Props {
  text?: string;
  handleClose: () => void;
  style?: React.CSSProperties;
}

export const Snackbar = ({ text, handleClose, style }: Props) => (
  <div className={css.snackbar} style={style}>
    <p>{text}</p>
    <button className={css.button} onClick={handleClose}>
      X
    </button>
  </div>
);
