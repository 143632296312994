/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UserStateViewModel } from "@/src/ui/view_models/user_state";
import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";
import { isUserLoggedIn } from "@/src/core/app/data/utils/drupal";

export const userProviderStore = createStore<UserStateViewModel>()(
  immer((set) => ({
    isInitiated: false,
    isLogged: false,
    init: async () => {
      set((state) => {
        state.isInitiated = true;
        state.isLogged = isUserLoggedIn();
      });
    },
    login() {
      set((state) => {
        state.isLogged = true;
      });
    },
    logout() {
      set((state) => {
        state.isLogged = false;
      });
    }
  }))
);

export function useUserProvider(): UserStateViewModel;
export function useUserProvider<T>(selector: (state: UserStateViewModel) => T, equals?: (a: T, b: T) => boolean): T;
export function useUserProvider(selector?: any, equals?: any) {
  return useStore(userProviderStore, selector, equals);
}
