import { getRequestConfig } from "next-intl/server";
import { createSharedPathnamesNavigation } from "next-intl/navigation";
import type { LocalePrefix } from "next-intl/dist/types/src/shared/types";

export const locales = ["es"];

export const defaultLocale = "es";

export const localePrefix: LocalePrefix = "never"; // Default

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({ locales, localePrefix });

export default getRequestConfig(async ({}) => {
  // Validate that the incoming `locale` parameter is valid
  // if (!locales.includes(locale)) notFound();

  return {
    locale: defaultLocale,
    messages: {
      home: (await import(`./messages/${defaultLocale}/home.json`)).default,
      rules: (await import(`./messages/${defaultLocale}/rules.json`)).default,
      circuit: (await import(`./messages/${defaultLocale}/circuit.json`)).default,
      leaderboard: (await import(`./messages/${defaultLocale}/leaderboard.json`)).default,
      register: (await import(`./messages/${defaultLocale}/register.json`)).default,
      login: (await import(`./messages/${defaultLocale}/login.json`)).default,
      forgot_password: (await import(`./messages/${defaultLocale}/forgot_password.json`)).default,
      send_time: (await import(`./messages/${defaultLocale}/send_time.json`)).default,
      my_times: (await import(`./messages/${defaultLocale}/my_times.json`)).default
    }
  };
});
