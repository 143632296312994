
    export const TYPES = {
      RestService: Symbol.for('RestService'),
IEnvVars: Symbol.for('IEnvVars'),
ILeaderboardRepository: Symbol.for('ILeaderboardRepository'),
GetLeaderboardEntriesUseCase: Symbol.for('GetLeaderboardEntriesUseCase'),
GetMyTimesUseCase: Symbol.for('GetMyTimesUseCase'),
SendTimeUseCase: Symbol.for('SendTimeUseCase'),
IUserRepository: Symbol.for('IUserRepository'),
ForgotPasswordUseCase: Symbol.for('ForgotPasswordUseCase'),
LoginUseCase: Symbol.for('LoginUseCase'),
LogoutUseCase: Symbol.for('LogoutUseCase'),
RegisterUseCase: Symbol.for('RegisterUseCase'),
ResetPasswordUseCase: Symbol.for('ResetPasswordUseCase'),
    };