// This is an auto generated file created by inversify-generator
// Please don't make any changes as you might lose them in future generations
import "reflect-metadata";
import { Container } from "inversify";
import { TYPES } from "./types";
import { bindDynamicModule } from "inversify-generator/utils";
import { EnvVars } from "../../domain/models/env_vars";

const locator = new Container();
bindDynamicModule(TYPES.RestService, () => import("../../data/services/rest_service"), locator);
locator.bind(TYPES.IEnvVars).to(EnvVars);
bindDynamicModule(TYPES.ILeaderboardRepository, () => import("../../../leaderboard/data/repositories/leaderboard_repository"), locator);
bindDynamicModule(TYPES.GetLeaderboardEntriesUseCase, () => import("../../../leaderboard/domain/use_cases/get_leaderboard_entries_use_case"), locator);
bindDynamicModule(TYPES.GetMyTimesUseCase, () => import("../../../leaderboard/domain/use_cases/get_my_times_use_case"), locator);
bindDynamicModule(TYPES.SendTimeUseCase, () => import("../../../leaderboard/domain/use_cases/send_time_use_case"), locator);
bindDynamicModule(TYPES.IUserRepository, () => import("../../../users/data/repository/user_repository"), locator);
bindDynamicModule(TYPES.ForgotPasswordUseCase, () => import("../../../users/domain/use_cases/forgot_password_use_case"), locator);
bindDynamicModule(TYPES.LoginUseCase, () => import("../../../users/domain/use_cases/login_use_case"), locator);
bindDynamicModule(TYPES.LogoutUseCase, () => import("../../../users/domain/use_cases/logout_use_case"), locator);
bindDynamicModule(TYPES.RegisterUseCase, () => import("../../../users/domain/use_cases/register_use_case"), locator);
bindDynamicModule(TYPES.ResetPasswordUseCase, () => import("../../../users/domain/use_cases/reset_password_use_case"), locator);

export { locator };