/* eslint-disable @typescript-eslint/no-explicit-any */
import { createProvider } from "@/src/common/utils/zustand";
import type { SnackbarProviderState } from "../view_models/snackbar_state";

const TIME_TO_REMOVE_SNACKBAR = 5000;

export const useSnackbarProvider = createProvider<SnackbarProviderState>(() => (set, get) => ({
  snackbars: [],
  addSnackbar(text: string) {
    const key = crypto.getRandomValues(new Uint32Array(1))[0].toString();

    setTimeout(() => {
      get().removeSnackbar(key);
    }, TIME_TO_REMOVE_SNACKBAR);

    set((state) => {
      state.snackbars = [...state.snackbars, { key, text }];
    });
  },
  removeSnackbar(key: string) {
    set((state) => {
      state.snackbars = state.snackbars.filter((snackbar) => snackbar.key !== key);
    });
  }
}));
