import { Expose } from "class-transformer";

export class DrupalDataModel<T> {
  @Expose()
  value: T;

  constructor(value: T) {
    this.value = value;
  }

  toDomain() {
    return this.value;
  }
}
